import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { useRecordContext } from "ra-core";
import { apiUrl, httpClient } from "../../../utils/utilsFetch";

export const RenderExceptions = ({ label, value, onChange, index }) => {
  const [options, setOptions] = useState([]);
  const record = useRecordContext();

  const getOptions = async (type) => {
    httpClient(`${apiUrl}/entities/all/${type}`, options)
      .then(async (res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json;
        }
        throw await res.json;
      })
      .then((json) => {
        if (json) {
          setOptions(json || []);
        }
      });
  };
  useEffect(() => {
    if (label) {
      const attribute = record.attributes.find((i) => i?.name.includes(label));
      if (attribute && attribute?.format == "options" && attribute?.options) {
        setOptions(attribute.options || []);
      } else {
        if (attribute?.format == "relation") {
          getOptions(attribute.relation_type_id);
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl size="small">
      <InputLabel
        id={`select-options-${label}`}
        style={{ paddingLeft: "10px", paddingTop: "4px" }}
        size="small"
      >
        {label}
      </InputLabel>
      <Select
        labelId={`select-options-${label}`}
        id="options"
        value={value || ""}
        variant="filled"
        size="small"
        style={{ minWidth: "180px" }}
        onChange={(e) => onChange(index, label, e.target.value)}
      >
        {options?.map((i, key) => (
          <MenuItem key={key} value={i?.name || i}>
            {i?.name || i}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
