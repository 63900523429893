import * as React from "react";
import { useFormState, useField } from "react-final-form";
import { ArrayInputAttributes } from "./ArrayInputAttributes";

export const Attributes = ({ types, mode = "create", record }) => {
  const { values } = useFormState();
  const [selectType, setSelectTypes] = React.useState();
  const [attributes, setAttributes] = React.useState();

  const type = values?.type_id;
  const { input: input_attributes } = useField("attributes");

  const getType = async () => {
    let find = types?.find((i) => i?.id === type);
    find.attributes = find.attributes.map((i) => {
      const res = { ...i, attribute_id: i?.id };
      delete res.id;
      return res;
    });
    if (mode === "create") {
      setAttributes(find?.attributes || []);
      input_attributes.onChange(find?.attributes || []);
    } else {
      setSelectTypes(find || []);
    }
  };

  React.useEffect(() => {
    if (type && types) {
      getType();
    }
  }, [type, types]); // eslint-disable-line react-hooks/exhaustive-deps

  const transformInit = (values) => {
    return values?.map((i) => {
      let res = { ...i };
      if (res.value) {
        try {
          let objects = JSON.parse(res.value);
          let new_val = Object.values(objects).find((v) => {
            if (v != null) {
              return v;
            }
            return null;
          });
          if (new_val) {
            res.value = new_val;
          }
        } catch (error) {
    
        }
      }
      res.format = res?.attribute?.format || res?.format;
      res.options = res.attribute?.options || res?.options;
      if (
        res.format === "options" &&
        (res.value === undefined || res.value == null)
      ) {
        res.value = [];
      } else {
        if (res.format === "options" && typeof res.value == "string") {
          res.value = [res.value];
        }
      }
      if (
        res.format === "relation" &&
        (res.value === undefined || res.value == null)
      ) {
        res.value = [];
      } else {
        if (res.format === "relation" && typeof res.value == "string") {
          res.value = [res.value];
        }
      }
      if (res.format === "list" && (res.value == null || undefined)) {
        res.value = [];
      } else {
        if (res.format === "list" && typeof res.value == "string") {
          res.value = [res.value];
        }
      }

      return res;
    });
  };

  React.useEffect(() => {
    //cuando cambia el tipo en el modo create
    if (mode === "create" && selectType) {
      setAttributes(transformInit(selectType.attributes));
    }
  }, [selectType]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (attributes && attributes?.length > 0) {
      input_attributes.onChange(attributes);
    }
  }, [attributes]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (record && record?.attributes?.length > 0 && !attributes) {
      setAttributes(record?.attributes);
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!type || attributes?.length === 0) return null;
  return <ArrayInputAttributes data={attributes} setData={setAttributes} />;
};
