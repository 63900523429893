import React, { useEffect } from "react";
import { TextField, Box, InputAdornment, Card, CardContent } from "@material-ui/core";
export const ObjectRender = ({
  title,
  value,
  onChange,
  new_object,
  isRequired,
}) => {
  const handleItemChange = (fieldName, fieldValue) => {
    const updatedItems = { ...value };
    updatedItems[fieldName] = fieldValue;
    onChange(updatedItems);
  };

  useEffect(() => {
    if (new_object) {
      let new_obj = { ...new_object, ...value };
      onChange(new_obj);
    }
  }, [new_object]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card style={{maxWidth: "500px"}}>
      <CardContent>
        <Box display="flex" flexDirection="column" style={{ gap: "10px" }}>
          {value && Object.entries(value)?.length > 0
            ? Object.entries(value)?.map(([key, value], ind) =>
                key !== "id" ? (
                  <TextField
                    label={key}
                    value={value || ""}
                    variant="filled"
                    size="small"
                    key={`${ind}-${key}`}
                    InputProps={
                      key == "twitter"
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                https://twitter.com/
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                    onChange={(e) => handleItemChange(key, e.target.value)}
                  />
                ) : null
              )
            : null}
        </Box>
      </CardContent>
    </Card>
  );
};
