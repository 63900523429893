import * as React from "react";
import { AutocompleteInput, BooleanInput, Create, ReferenceInput, SimpleForm, TextInput, required } from 'react-admin';
import { useTranslate } from "ra-core";

export const RelationTypeCreate = ({ onSave, props }) => {
    const translate = useTranslate();
    const onSuccess = (data) => {
        if (onSave) {
            onSave(data);
        }
    }
    return (
        <Create title=" " resource="relation_types" basePath="/relation_types" {...props} onSuccess={onSuccess} redirect={false}>
            <SimpleForm redirect={false}>
                <TextInput source="name" validate={[required()]} />
                <ReferenceInput source="type_id" reference="types" filterToQuery={(searchText) => ({
                    search: searchText,
                })}>
                    <AutocompleteInput source="name" optionText="name" optionValue="id" label="Entidad destino de la relación" />
                </ReferenceInput>
                <BooleanInput source="show" label={translate("components.add_in_notes")} />
            </SimpleForm>
        </Create>
    )
};