import * as React from "react";
import {
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  useTranslate
} from "react-admin";
import { ActionsCreate } from "../../../components/Toolbar/ActionsCreate";

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
  </Filter>
);


export const TypeList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <List
      filters={<CategoriesFilter />}
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
      title=" "
      actions={<ActionsCreate label={`${translate("resources.types.new")}`}/>}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <BooleanField source="show" />
        {permissions &&
          permissions.some(
            (permission) =>
              permission.name === "*" || permission.name === "edit-types"
          ) && <EditButton />}
      </Datagrid>
    </List>
  );
};
