import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import { useField } from "react-final-form";
import CustomArrayInput from "./CustomArrayInput";
import { checkPermissions } from "../../../utils/validations";
import { USERSUPERADMIN } from "../../../utils/configs";

const OPTIONS = [
  { id: "number", name: "Número" },
  { id: "text", name: "Texto" },
  { id: "longtext", name: "Texto Largo" },
  { id: "date", name: "Fecha" },
  { id: "options", name: "Opciones" },
  { id: "list", name: "Lista" },
  { id: "image", name: "Imagen" },
  { id: "relation", name: "Relación" },
  { id: "object", name: "Objeto" },
];

export const FormType = ({ ...props }) => {
  const t = useTranslate();
  const { input: input_attributes } = useField("attributes");
  const user = JSON.parse(localStorage.getItem("auth"));
  const saveChanges = (values) => {
    input_attributes.onChange(values);
  };
  return (
    <>
      <TextInput source="name" validate={[required()]} />
      {checkPermissions("edit-entity-type-view", props.permissions) &&
      user.email === USERSUPERADMIN ? (
        <BooleanInput source="show" label={`${t("resources.types.fields.show")}`} />
      ) : null}
      <CustomArrayInput
        label={t("resources.types.fields.attributes")}
        onChange={saveChanges}
        choices={OPTIONS}
      />
    </>
  );
};

export const TypeCreate = (props) => {
  return (
    <Create title=" " {...props}>
      <SimpleForm>
        <FormType permissions={props.permissions} />
      </SimpleForm>
    </Create>
  );
};
