import * as React from "react";
import {
  DeleteWithConfirmButton,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
  useTranslate,
} from "react-admin";
import ImagePicker from "../../../components/pickers/ImagePicker";
import { apiUrl, httpClient } from "../../../utils/utilsFetch";
import { Attributes } from "./Attributes";
import { Avatar, Descriptions, Select } from "./create";

const EditToolbar = ({ ...props }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Toolbar
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      {...props}
    >
      <SaveButton handleSubmitWithRedirect={props?.handleSubmitWithRedirect} />
      <DeleteWithConfirmButton
        confirmTitle={`${translate("resources.entities.confirm_title", {
          name: record.name,
        })}`}
        confirmContent={`${translate("resources.entities.confirm_content")}`}
      />
    </Toolbar>
  );
};

export const EntityEdit = (props) => {
  const [types, setTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getTypes = async () => {
    const res = await httpClient(apiUrl + "/types?page=1&per_page=10000");

    if (res?.status === 200) {
      const data = await res.json;
      setTypes(data?.data);
      setLoading(false);
    } else {
      setTypes([]);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getTypes();
  }, []);

  if (loading) return null;
  return (
    <Edit title=" " {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" resource="entities" validate={[required()]} />
        <TextInput source="order" resource="entities" />
        <Descriptions />
        <Avatar />
        {types ? <Select types={types} mode="edit" /> : null}
        {types ? <Attributes types={types} mode="edit" /> : null}
        <ImagePicker permissions={props.permissions} />
      </SimpleForm>
    </Edit>
  );
};
