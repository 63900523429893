import React, { useEffect, useState } from "react";
import { AutocompleteArrayInput, required, useDataProvider } from "react-admin";
import { useField } from "react-final-form";
import { apiUrl, httpClient } from "../../../utils/utilsFetch";

export const RelationField = ({ source, item, onChange, isRequired }) => {
  const [relationType, setRelationType] = useState();
  const dataProvider = useDataProvider();
  const [current, setCurrent] = useState();
  const { input } = useField(source);
  const [options, setOptions] = useState();

  const getOptions = async () => {
    httpClient(`${apiUrl}/entities/all/${relationType?.type_id}`)
      .then(async (res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json;
        }
        throw await res.json;
      })
      .then((json) => {
        if (json) {
          setOptions(json || []);
        }
      });
  };
  useEffect(() => {
    if (relationType) {
      getOptions();
    }
  }, [relationType]); // eslint-disable-line react-hooks/exhaustive-deps

  const getOne = async () => {
    dataProvider
      .getOne("relation_types", { id: item?.relation_type_id })
      .then((data) => {
        if (data) {
          setRelationType(data.data);
        }
      })
      .catch((error) => {
        setRelationType(null);
      });
  };

  useEffect(() => {
    if (item?.relation_type_id) {
      getOne();
    }
  }, [item?.relation_type_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (item?.value?.length !== current?.length || !current) {
      setCurrent(item.value);
      input.onChange(item.value);
    }
  }, [item?.value]); // eslint-disable-line react-hooks/exhaustive-deps
  if (
    relationType === undefined ||
    relationType == null ||
    !item?.relation_type_id ||
    !options
  )
    return null;

  const validation = isRequired ? required() : null;

  return (
    <AutocompleteArrayInput
      source={source}
      multiple
      optionValue="id"
      optionText="name"
      label="Relacionado"
      size="small"
      margin="dense"
      onChange={(val) => onChange(val)}
      choices={options || []}
      validate={validation}
    />
  );
};
