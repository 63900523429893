import * as React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  required,
  useQueryWithStore,
} from "react-admin";
import { useFormState } from "react-final-form";
const RelationTypes = ({ setTypes }) => {
  const { data: choices } = useQueryWithStore({
    type: "getList",
    resource: "relation_types",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 1000 },
    },
  });
  React.useEffect(() => {
    setTypes(choices);
  }, [choices]); // eslint-disable-line react-hooks/exhaustive-deps

  return choices ? (
    <AutocompleteInput
      source="relation_type_id"
      optionText="name"
      optionValue="id"
      choices={choices}
      validate={[required()]}
    />
  ) : null;
};

export const Form = () => {
  const { values } = useFormState();
  const [types, setTypes] = React.useState();

  const getType = () => {
    return types?.find((i) => i?.id === values?.relation_type_id)?.id || null;
  };
  return (
    <>
      <RelationTypes setTypes={setTypes} />
      {values?.relation_type_id ? (
        <ReferenceInput
          source="entity_id"
          reference="entities"
          filterToQuery={(q) => ({ search: q, type_id: getType() })}
        >
          <AutocompleteInput source="name" optionText="name" optionValue="id" />
        </ReferenceInput>
      ) : null}

      <ReferenceInput
        source="related_id"
        reference="entities"
        filterToQuery={(searchText) => ({
          search: searchText,
        })}
      >
        <AutocompleteInput source="name" optionText="name" optionValue="id" />
      </ReferenceInput>
    </>
  );
};

export const RelationCreate = (props) => (
  <Create title=" " {...props}>
    <SimpleForm>
      <Form />
    </SimpleForm>
  </Create>
);
