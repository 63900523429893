import { Box, Typography } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { useField, useFormState } from "react-final-form";
import ImagePicker from "../../../components/pickers/ImagePicker";
import { apiUrl, httpClient } from "../../../utils/utilsFetch";
import { Attributes } from "./Attributes";
import { ImageInput } from "./ImageInput";

export const Select = ({ types, mode = "create" }) => {
  const t = useTranslate();
  return (
    <SelectInput
      source="type_id"
      choices={types}
      validate={[required()]}
      label={t("resources.entities.fields.type_id")}
      disabled={mode === "edit"}
    />
  );
};

export const Avatar = () => {
  const { input: avatar_input } = useField("avatar");
  const record = useRecordContext();

  const handleSave = (value) => {
    avatar_input.onChange(value);
  };
  return (
    <Box width="390px">
      <ImageInput
        source={"avatar"}
        data={record?.avatar}
        handleSelected={(val) => handleSave(val)}
        label="Avatar"
      />
    </Box>
  );
};

export const customValidate = (value, max) => {
  let error = "";
  let cleanText = value?.replace(/<\/?[^>]+(>|$)/g, "");
  if (!cleanText) {
    error = "ra.validation.required";
  }
  if (cleanText && max && cleanText?.length > max) {
    error = `Debe contener ${max} caracteres o menos`;
  }
  return error;
};

export const customValidateLength = (value, max) => {
  let error = "";
  let cleanText = value?.replace(/<\/?[^>]+(>|$)/g, "");
  if (cleanText && max && cleanText?.length > max) {
    error = `Debe contener ${max} caracteres o menos`;
  }
  return error;
};

export const getContChars = (values, source, max) => {
  let result = "";
  if (values && values[source]) {
    let text = values[source];
    let cleanText = text?.replace(/<\/?[^>]+(>|$)/g, "");
    result = `. Caracteres ${cleanText?.length || 0} / ${max}`;

    return result;
  }
  return "";
};

export const Descriptions = () => {
  const { values } = useFormState();
  const maxLengthRichText = 8500;
  return (
    <>
      <Typography variant="body1">Descripción corta</Typography>
      <RichTextInput
        label=""
        source={`short_description`}
        toolbar={[["bold", "italic", "link"]]}
        helperText={`Describí en pocas palabras qué es lo que incluye${getContChars(
          values,
          "short_description",
          120
        )}`}
        fullWidth
        validate={(values) =>
          customValidateLength(values, "short_description", 120)
        }
      />
      <Typography variant="body1" style={{ marginTop: "20px" }}>
        Descripción *
      </Typography>
      <RichTextInput
        label=""
        source={`description`}
        toolbar={[
          [
            "bold",
            "italic",
            "link",
            "underline",
            "blockquote",
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { script: "sub" },
            { script: "super" },
            { indent: "-1" },
            { indent: "+1" },
            { direction: "rtl" },
            "clean",
          ],
        ]}
        helperText={`Describí en pocas palabras qué es lo que incluye${
          maxLengthRichText
            ? getContChars(values, "description", maxLengthRichText)
            : ""
        }`}
        fullWidth
        validate={(values) =>
          customValidate(values, "description", maxLengthRichText)
        }
      />
      <SelectInput
        source="state"
        choices={[
          { id: "draft", name: "Borrador" },
          { id: "publish", name: "Publicada" },
          { id: "disable", name: "Deshabilitada" },
        ]}
        label="Estado"
        defaultValue={"draft"}
      ></SelectInput>
    </>
  );
};

export const EntityCreate = (props) => {
  const [types, setTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getTypes = async () => {
    const res = await httpClient(apiUrl + "/types?page=1&per_page=10000");
    if (res?.status === 200) {
      const data = await res.json;
      setTypes(data?.data);
      setLoading(false);
    } else {
      setTypes([]);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getTypes();
  }, []);

  if (loading) return null;
  return (
    <Create title=" " {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="order" />
        <Descriptions />
        <Avatar />
        {types ? <Select types={types} /> : null}
        {types ? <Attributes types={types} record={props?.record} /> : null}
        <ImagePicker permissions={props.permissions} />
      </SimpleForm>
    </Create>
  );
};
